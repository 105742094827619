@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-4xl;
  }
  h2 {
    @apply text-xl;
  }
  h3 {
    @apply text-lg;
  }
  a {
    @apply text-crimson-300 underline decoration-dotted decoration-1;
  }
  ul {
    @apply list-disc list-inside;
  }
  ol {
    @apply list-decimal list-inside;
  }
}

/* only apply to chrome - it has weird artefacts on animating the weight of subpixel rendered fonts (on windows only) */
@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  #title {
    text-rendering: grayscale;
  }
}

:root {
  --font-min: 100;
  --font-max: 900;
}

@keyframes wiggle {
  0%,
  100% {
    font-weight: var(--font-min);
  }
  50% {
    font-weight: var(--font-max);
  }
}

.woobly > span {
  font-weight: var(--font-min); /* initial */
  animation: wiggle 3.4s ease-in-out infinite;
}

.ql > li + li {
  margin-top: 1em;
}

/* unused: */
.colourise {
  filter: brightness(40%) invert(16%) sepia(15%) saturate(467%)
    hue-rotate(145deg) brightness(94%) contrast(90%);
}

.card {
  position: relative;
}

.card::before {
  z-index: -1;
  @apply colourise;
  @apply bg-black border border-white;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cardBg {
  @apply text-grime-200;
  background: transparent;
  background-image: radial-gradient(currentColor 1px, transparent 0);
  background-size: 10px 10px;
  background-position: calc(70px * sin(var(--angle)))
    calc(70px * cos(var(--angle)));

  animation: bgScroll 10s linear infinite;
}

@property --angle {
  syntax: "<number>";
  initial-value: 0;
  inherits: false;
}

@keyframes bgScroll {
  0% {
    --angle: 0;
  }
  100% {
    --angle: calc(360 * (3.14159 / 180));
  }
}

@keyframes hazard-scroll {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -8px 8px;
  }
}
